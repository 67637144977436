import MarkdownDisplay from "../../components/MarkdownDisplay";
import { Article as ArticleProps } from "./Articles/articles";

// TODO: add SEO by using article title and description
export const Article = (props: { article: ArticleProps }) => {
  const { article } = props;
  return (
    <>
      <div className="text-right italic">{article.date.toDateString()}</div>
      <MarkdownDisplay markdownFile={article.content} />
    </>
  );
};
