import { Link } from "react-router-dom";
import { articles } from "./Articles/articles";
import logo from "../../assets/flag.svg";
export function News() {
  return (
    <div className="flex flex-col gap-10">
      {Object.entries(articles).map(([link, { title, image, description, date }], index) => (
        <Link
          key={index}
          to={link}
          className="flex sm:flex-row flex-col gap-4 w-full items-center border-4 rounded-xl border-black p-4 bg-[#FDF1B2]"
        >
          <img src={image || logo} alt="flag" className="h-[6rem] sm:h-[7rem]" />
          <div className="flex flex-col gap-2">
            <div className="opacity-0">{title}</div>
            <div className="text-xl font-bold text-center sm:text-left">{title}</div>
            {description}
            <div className="text-right italic">{date.toDateString()}</div>
          </div>
        </Link>
      ))}
    </div>
  );
}
