import { About } from "../pages/About/About";
import { News } from "../pages/News/News";

interface NavigationElement {
  link?: string;
  title?: string;
  component?: JSX.Element;
}

export const NavigationElements: NavigationElement[] = [
  { title: "Home", link: "/" },
  { title: "About", link: "/about", component: <About /> },
  { title: "People", link: "/people" },
  { title: "News", link: "/news", component: <News /> },
  { title: "Shop", link: "/shop" },
];
