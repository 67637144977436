interface FooterBoxProps {
  children: React.ReactNode;
  icon?: string;
}

function FooterBox(props: FooterBoxProps) {
  const { children, icon } = props;
  return (
    <div className="flex flex-row items-center gap-2 mx-auto md:mx-0 font-bold">
      <span className={`fa-solid ${icon}`} />
      {children}
    </div>
  );
}

export default function Footer() {
  return (
    <div className="w-full flex md:flex-row flex-col justify-around md:gap-12 gap-2 md:mt-0 px-4 z-[5] py-4">
      <FooterBox icon="fa-phone">07545168572</FooterBox>
      <FooterBox icon="fa-envelope">
        <a href="mailto:dojokaizenkarate@aniltailor.com" className="hover:underline">
          dojokaizenkarate@aniltailor.com
        </a>
      </FooterBox>
      <FooterBox icon="fa-map-pin">1 Coral St, London, SE1 7BE</FooterBox>
    </div>
  );
}
