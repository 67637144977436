import _2023_01_01 from "./scottish_open_2023.md";

export interface Article {
  title: string;
  description?: string;
  content: string;
  date: Date;
  keywords?: string[];
  image?: string;
}

export const articles: Record<string, Article> = {
  "scottish-open-2023": {
    title: "3 trophies for Kaizen at the Scottish Open",
    description:
      "4 of our dojo members travelled to Scotland this weekend to fight in the Scottish Open. The outcome was great, with 1 gold, 1 silver, and 1 bronze accross 3 divisions",

    content: _2023_01_01,
    date: new Date("04 March 2023"),
  },
};
