import { BrowserRouter, Link, Route, Routes, useLocation } from "react-router-dom";
import logo from "./assets/flag.svg";
import Footer from "./components/Footer";
import { NavigationElements } from "./helpers/navigation";
import { Article } from "./pages/News/Article";
import { articles } from "./pages/News/Articles/articles";
import { NotFound } from "./pages/notfound";

const NavBar = () => {
  const location = useLocation();

  return (
    <div className="flex flex-row gap-4 flex-wrap text-2xl font-bold mx-auto justify-center">
      {NavigationElements.filter((el) => el.title !== undefined).map(({ link, title }, index) => (
        <Link
          to={link || "/"}
          key={index}
          className={`p-2 ${
            "/" + location.pathname.split("/")[1] === link ? "bg-black text-[#ffdd02]" : "text-black bg-[#ffdd02]"
          }`}
        >
          {title!.toUpperCase()}
        </Link>
      ))}
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <div className="bg-[#ffdd0266] w-screen min-h-screen h-max p-4">
        <div className="bg-[#ffdd02] max-w-[60rem] mx-auto p-8 border-4 border-black rounded-2xl flex flex-col gap-8">
          <div className="ninja flex flex-row gap-4 items-center flex-wrap">
            <img src={logo} alt="flag" className="h-[10rem] mx-auto" />
            <div className="flex flex-col text-center mx-auto">
              <div className="text-4xl">Kyokushinkai Karate</div>
              <div className="text-6xl">Dojo Kaizen</div>
            </div>
          </div>
          <div className="w-full border-2 border-black"></div>
          <NavBar />
          <div className="w-full border-2 border-black"></div>
          <div className="p-0 md:px-10">
            <Routes>
              {NavigationElements.map(
                ({ link, title, component }, index) =>
                  link && <Route key={index} path={link} element={component ? component : <>{title}</>} />
              )}
              {Object.entries(articles).map(([link, article], index) => (
                <Route key={index} path={`/news/${link}`} element={<Article article={article} />} />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
