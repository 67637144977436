import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { ClipLoader } from "react-spinners";
import remarkGfm from "remark-gfm";

interface MarkdownDisplayProps {
  markdownFile: string;
}

export default function MarkdownDisplay(props: MarkdownDisplayProps) {
  const { markdownFile } = props;
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(markdownFile).then((t) =>
      t.text().then((t) => {
        setContent(t);
        setLoading(false);
      })
    );
  }, [markdownFile]);
  return (
    <>
      {/* <PacmanLoader
        loading={loading}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
        className="mx-auto pr-24 my-10"
      /> */}
      <div className="text-center">
        <ClipLoader
          loading={loading}
          size={35}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="!mx-auto"
        />
      </div>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        skipHtml={false}
        transformImageUri={(e) => e.replace("/public", "")}
        components={{
          h1: ({ node, ...props }) => <div className="text-6xl font-bold py-6" {...props}></div>,
          h2: ({ node, ...props }) => <div className="text-5xl font-bold py-6" {...props}></div>,
          h3: ({ node, ...props }) => <div className="text-4xl font-bold py-6" {...props}></div>,
          h4: ({ node, ...props }) => <div className="text-3xl font-bold py-6" {...props}></div>,
          h5: ({ node, ...props }) => <div className="text-2xl font-bold py-6" {...props}></div>,
          h6: ({ node, ...props }) => <div className="text-xl font-bold py-6" {...props}></div>,
          li: ({ node, ...props }) => <li className="ml-10 py-1" {...props}></li>,
          ul: ({ node, ...props }) => <ul className="py-2" {...props}></ul>,
          img: ({ node, ...props }) => (
            <img
              className="border-black border-4 m-10 mx-auto max-w-[90%] max-h-[80vh] lg:max-h-[50vh] md:max-h-[60vh]"
              {...props}
              alt={props.alt}
            />
          ),
          table: ({ node, ...props }) => <table className="w-full" {...props}></table>,
          tbody: ({ node, ...props }) => <tbody className="text-center" {...props}></tbody>,
          tr: ({ node, ...props }) => (
            <tr className="flex-wrap md:flex-nowrap flex w-full justify-center" {...props}></tr>
          ),
        }}
        className="max-h-full"
      >
        {content}
      </ReactMarkdown>
    </>
  );
}
