import MarkdownDisplay from "../../components/MarkdownDisplay";
import about from "./about.md";

// const InfoBox = (props: { children: React.ReactNode; icon?: string }) => {
//   const { children, icon } = props;
//   return (
//     <div className="flex flex-row gap-2 font-bold text-large">
//       <span className={`fa-solid ${icon} mt-1`} />
//       {children}
//     </div>
//   );
// };

export function About() {
  return (
    // <div className="flex flex-row gap-4 flex-wrap md:flex-nowrap justify-between">
    <MarkdownDisplay markdownFile={about} />

    // <div className="flex flex-col gap-4 justify-end mx-auto md:pt-0 pt-10">
    //   <InfoBox icon="fa-clock">
    //     MONDAY 7:30 pm - 9:00 pm &<br />
    //     WEDNESDAY 7:45 pm - 9:15 pm
    //   </InfoBox>
    //   <InfoBox icon="fa-phone">07545168575</InfoBox>
    //   <InfoBox icon="fa-envelope">
    //     <a href="mailto:dojokaizenkarate@aniltailor.com" className="hover:underline">
    //       dojokaizenkarate@aniltailor.com
    //     </a>
    //   </InfoBox>
    //   <InfoBox icon="fa-map-pin">Living Space - 1 Coral St, London, SE1 7BE</InfoBox>
    // </div>
    // </div>
  );
}
